export enum EPermissions {
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
  ORDER_VIEW = "ORDER_VIEW",
  ORDER_SUMMARY_VIEW = "ORDER_SUMMARY_VIEW",
  MENU_VIEW = "MENU_VIEW",
  TAG_VIEW = "TAG_VIEW",
  CATEGORY_VIEW = "CATEGORY_VIEW",
  ALLERGEN_VIEW = "ALLERGEN_VIEW",
  LOCATION_VIEW = "LOCATION_VIEW",
  DELIVERY_COVERAGE = "DELIVERY_COVERAGE",
  SCHEDULE_VIEW = "SCHEDULE_VIEW",
  DISCOUNT_VIEW = "DISCOUNT_VIEW",
  ADMIN_VIEW = "ADMIN_VIEW",
  CUSTOMER_VIEW = "CUSTOMER_VIEW",
  CUSTOMER_MESSAGE_VIEW = "CUSTOMER_MESSAGE_VIEW",
  BANNER_VIEW = "BANNER_VIEW",
  HOW_IT_WORK_VIEW = "HOW_IT_WORK_VIEW",
  ABOUT_US_VIEW = "ABOUT_US_VIEW",
  PRIVACY_POLICY_VIEW = "PRIVACY_POLICY_VIEW",
  TERM_AND_CONDITION_VIEW = "TERM_AND_CONDITION_VIEW",
  FAQ_VIEW = "FAQ_VIEW",
  PACKAGING_VIEW = "PACKAGING_VIEW",
}
