import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import {
  EShipmentMethod,
  TransactionProps,
} from '../../types/transaction.type';
import { useMemo } from 'react';

interface PDFProps {
  data: TransactionProps[];
}

// Create styles
const styles = StyleSheet.create({
  section: {
    padding: 20,
  },
  em: {
    fontStyle: 'bold',
  },
  table: {
    // borderWidth: 2,
    width: 525,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 12,
    marginHorizontal: '15px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    borderWidth: 1,
  },
  rowDetail: {
    margin: '20px',
  },
  font: {
    fontSize: 14,
    marginBottom: '10px',
  },

  fontItem: {
    fontSize: 14,
    marginBottom: '5px',
  },
});

function getOrderScheduleDay(date: string): string {
  const scheduleAt = new Date(date).getDay();
  let day = '';
  switch (scheduleAt) {
    case 0:
      day = 'Sunday';
      break;
    case 1:
      day = 'Monday';
      break;
    case 2:
      day = 'Tuesday';
      break;
    case 3:
      day = 'Wednesday';
      break;
    case 4:
      day = 'Thursday';
      break;
    case 5:
      day = 'Friday';
      break;
    case 6:
      day = 'Saturday';
      break;
    default:
      day = 'Day is not existing.';
  }
  return day;
}

function dateFormat(date: string) {
  const dateDay = new Date(date);
  const day = dateDay.getDate();
  const monthIndex = dateDay.getMonth(); // 0-indexed
  const year = dateDay.getFullYear();

  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  const formattedDate = `${day} ${monthName[monthIndex]} ${year}`;

  return formattedDate;
}

function dateDeliveryFormat(date: string) {
  const dateDay = new Date(date);
  const day = dateDay.getDate();
  const month = dateDay.getMonth() + 1;
  const year = dateDay.getFullYear();

  const bulanNama = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ][month - 1];

  return `${day} ${bulanNama} ${year}`;
}

// Create Document Component
const MyDocument = ({ data }: PDFProps) => {
  function calculateGST(price: number) {
    return (price / 11) * 10;
  }

  function summary(transaction: TransactionProps) {
    let total = 0;
    let totalActual = 0;
    let gst = 0;
    let deliveryFee = transaction.deliveryFee || 0;
    let deliveryFeeActual = 0;
    let discountNominal = 0;

    for (const item of transaction.items) {
      total += item.productPrice * item.quantity;
    }

    if (transaction.shipment.method === EShipmentMethod.DELIVERY) {
      deliveryFeeActual = calculateGST(deliveryFee);
    }

    if (
      transaction.metaDiscount &&
      transaction.metaDiscount.discountName &&
      transaction.metaDiscount.discountNominal
    ) {
      discountNominal = transaction.metaDiscount.discountNominal;
    }

    totalActual = calculateGST(total);

    gst = total - totalActual + (deliveryFee - deliveryFeeActual);

    return {
      total: total,
      gst: discountNominal ? gst - discountNominal / 11 : gst,
      subTotal: totalActual + deliveryFeeActual + gst - discountNominal,
      discountNominal,
    };
  }

  return (
    <Document>
      {data.map((transaction, index) => {
        return (
          <Page wrap size='A4' style={styles.section}>
            <View wrap={true}>
              <View style={styles.table}>
                <View
                  key={index}
                  style={[styles.tableRow, index === 0 ? styles.em : {}]}
                >
                  {/* Header */}
                  <View
                    key={index}
                    style={[
                      styles.cell,
                      { width: '100%', height: 140, borderWidth: 1 },
                    ]}
                  >
                    <View style={{ width: '30%', borderRightWidth: 1 }}>
                      <View style={{ margin: 'auto' }}>
                        <Image
                          src='/images/logo-main.png'
                          style={{
                            width: '80%',
                            marginBottom: '15px',
                            marginHorizontal: 'auto',
                          }}
                        ></Image>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: 'Helvetica-Bold',
                            marginHorizontal: 'auto',
                          }}
                        >
                          Spoonful Kitchen
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: 'Helvetica-Bold',
                            marginHorizontal: 'auto',
                          }}
                        >
                          ABN: 45 677 604 544
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: 'Helvetica-Bold',
                            marginHorizontal: 'auto',
                          }}
                        >
                          20 Elliot Place, Ringwood 3134
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '70%' }}>
                      {/* Row 1 */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          height: 40,
                          borderBottomWidth: 1,
                        }}
                      >
                        <View
                          style={{
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginVertical: 'auto',
                          }}
                        >
                          <View style={{ width: '45%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              Invoice Date
                            </Text>
                          </View>
                          <View style={{ width: '5%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              :
                            </Text>
                          </View>
                          <View style={{ width: '50%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              {dateFormat(transaction.createdAt)}
                            </Text>
                          </View>
                        </View>
                        <View style={{ width: '10%' }}>{/* space */}</View>
                        <View
                          style={{
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginVertical: 'auto',
                          }}
                        >
                          <View style={{ width: '45%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              Invoice No.
                            </Text>
                          </View>
                          <View style={{ width: '5%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              :
                            </Text>
                          </View>
                          <View style={{ width: '50%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              {transaction.invoiceCode
                                ? transaction.invoiceCode
                                : ''}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {/* Row 2 */}
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          height: 40,
                          borderBottomWidth: 1,
                        }}
                      >
                        <View
                          style={{
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginVertical: 'auto',
                          }}
                        >
                          <View style={{ width: '45%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              {transaction.shipment.method ===
                              EShipmentMethod.DELIVERY
                                ? 'Delivery'
                                : 'Pickup'}{' '}
                              Date
                            </Text>
                          </View>
                          <View style={{ width: '5%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              :
                            </Text>
                          </View>
                          <View style={{ width: '50%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              {dateDeliveryFormat(
                                transaction.shipment.scheduledAt
                              )}
                            </Text>
                          </View>
                        </View>
                        <View style={{ width: '10%' }}>{/* space */}</View>
                        <View
                          style={{
                            width: '45%',
                            display: 'flex',
                            flexDirection: 'row',
                            marginVertical: 'auto',
                          }}
                        >
                          <View style={{ width: '45%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              Order ID
                            </Text>
                          </View>
                          <View style={{ width: '5%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              :
                            </Text>
                          </View>
                          <View style={{ width: '50%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica',
                                fontSize: 10,
                                marginLeft: 3,
                              }}
                            >
                              {transaction.transactionCode}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {/* Row 3 */}
                      <View
                        style={{
                          height: 60,
                          display: 'flex',
                          flexDirection: 'row',
                          width: '100%',
                        }}
                      >
                        <View
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            height: 60,
                          }}
                        >
                          <View
                            style={{
                              width: '80%',
                              display: 'flex',
                              flexDirection: 'row',
                              marginVertical: 'auto',
                            }}
                          >
                            <View
                              style={{ width: '25%', marginVertical: 'auto' }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Helvetica-Bold',
                                  fontSize: 10,
                                  marginLeft: 3,
                                }}
                              >
                                {transaction.shipment.method ===
                                EShipmentMethod.DELIVERY
                                  ? 'Delivery'
                                  : 'Pickup'}{' '}
                                To
                              </Text>
                            </View>
                            <View
                              style={{ width: '5%', marginVertical: 'auto' }}
                            >
                              <Text
                                style={{
                                  fontFamily: 'Helvetica',
                                  fontSize: 10,
                                  marginLeft: 3,
                                }}
                              >
                                :
                              </Text>
                            </View>
                            <View style={{ width: '69%' }}>
                              {transaction.shipment.method ===
                                EShipmentMethod.DELIVERY && (
                                <View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: 'Helvetica-Bold',
                                        marginBottom: 2,
                                        marginLeft: 2,
                                      }}
                                    >
                                      {transaction.metaBuyer?.fullName}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: 'Helvetica',
                                        marginBottom: 2,
                                        marginLeft: 2,
                                      }}
                                    >
                                      {'(' +
                                        transaction.metaBuyer?.phoneNumber +
                                        ')'}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontFamily: 'Helvetica',
                                      marginBottom: 2,
                                      marginLeft: 2,
                                    }}
                                  >
                                    {transaction.metaBuyer?.email}
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontFamily: 'Helvetica',
                                      marginLeft: 2,
                                      width: '90%',
                                    }}
                                  >
                                    {transaction.shipment?.meta?.deliveryAddress
                                      ? transaction.shipment?.meta
                                          ?.deliveryAddress +
                                        ', ' +
                                        transaction.shipment?.meta
                                          ?.deliveryAddressSuburb +
                                        ', ' +
                                        transaction.shipment?.meta
                                          ?.deliveryAddressState
                                      : transaction.shipment?.meta
                                          ?.pickupFromLocationName +
                                        ' - ' +
                                        transaction.shipment?.meta
                                          ?.pickupFromLocationAddress}
                                  </Text>
                                </View>
                              )}
                              {transaction.shipment.method ===
                                EShipmentMethod.PICKUP && (
                                <View>
                                  <View
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: 'Helvetica-Bold',
                                        marginBottom: 2,
                                        marginLeft: 2,
                                      }}
                                    >
                                      Spoonful Kitchen
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 10,
                                        fontFamily: 'Helvetica',
                                        marginBottom: 2,
                                        marginLeft: 2,
                                      }}
                                    >
                                      {'(' + '+61 3 8345 2000' + ')'}
                                    </Text>
                                  </View>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontFamily: 'Helvetica',
                                      marginBottom: 2,
                                      marginLeft: 2,
                                    }}
                                  >
                                    ABN: 45 677 604 544
                                  </Text>
                                  <Text
                                    style={{
                                      fontSize: 10,
                                      fontFamily: 'Helvetica',
                                      marginLeft: 2,
                                      width: '90%',
                                    }}
                                  >
                                    20 Elliot Place, Ringwood 3134
                                  </Text>
                                </View>
                              )}
                            </View>
                          </View>
                          <View
                            style={{
                              width: '20%',
                              backgroundColor: 'black',
                              display: 'flex',
                              borderWidth: 1,
                              marginLeft: 'auto',
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                color: 'white',
                                margin: 'auto',
                              }}
                            >
                              {transaction.shipment.method.toUpperCase()}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    paddingVertical: '10px',
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                  }}
                >
                  <View style={{ width: '3%' }}></View>
                  <View style={{ width: '15%' }}>
                    <Text
                      style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}
                    >
                      Order Details
                    </Text>
                  </View>
                  <View style={{ width: '5%' }}>
                    <Text
                      style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}
                    >
                      :
                    </Text>
                  </View>
                </View>

                {transaction.items.map((item, index) => {
                  return (
                    <View
                      wrap={false}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        paddingVertical: 5,
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        borderLeftWidth: 1,
                      }}
                    >
                      <View style={{ width: '5%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica',
                            fontSize: 10,
                            textAlign: 'right',
                            paddingRight: 3,
                          }}
                        >
                          {item.quantity}
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 10 }}>
                          x
                        </Text>
                      </View>
                      <View style={{ width: '79%' }}>
                        <Text style={{ fontFamily: 'Helvetica', fontSize: 10 }}>
                          {item.metaProduct.name}
                        </Text>
                      </View>
                      <View
                        style={{
                          width: '3%',
                          textAlign: 'right',
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: 'Helvetica',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          $
                        </Text>
                      </View>
                      <View style={{ width: '10%' }}>
                        <Text
                          style={{
                            width: '80%',
                            fontFamily: 'Helvetica',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          {(item.productPrice * item.quantity).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
                {transaction.shipment.method === EShipmentMethod.DELIVERY && (
                  <View
                    wrap={false}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                      top: -1,
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                      borderLeftWidth: 1,
                    }}
                  >
                    <View style={{ width: '3%' }}></View>
                    <View style={{ width: '84%' }}>
                      <Text
                        style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}
                      >
                        Delivery Fee :
                      </Text>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        {transaction.deliveryFee.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                )}

                <View
                  wrap={false}
                  style={{
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    borderBottom: 1,
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: '87%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ width: '90%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          Total
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          :
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        {summary(transaction).total.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                  {transaction.metaDiscount &&
                    transaction.metaDiscount.discountNominal > 0 && (
                      <View
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          paddingVertical: 5,
                        }}
                      >
                        <View
                          style={{
                            width: '87%',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <View style={{ width: '90%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                textAlign: 'right',
                              }}
                            >
                              {transaction.metaDiscount.discountName}
                            </Text>
                          </View>
                          <View style={{ width: '3%' }}>
                            <Text
                              style={{
                                fontFamily: 'Helvetica-Bold',
                                fontSize: 10,
                                textAlign: 'right',
                              }}
                            >
                              :
                            </Text>
                          </View>
                        </View>
                        <View style={{ width: '3%' }}>
                          <Text
                            style={{
                              fontFamily: 'Helvetica-Bold',
                              fontSize: 10,
                              textAlign: 'right',
                            }}
                          >
                            - $
                          </Text>
                        </View>
                        <View style={{ width: '10%' }}>
                          <Text
                            style={{
                              width: '80%',
                              fontFamily: 'Helvetica-Bold',
                              fontSize: 10,
                              textAlign: 'right',
                            }}
                          >
                            {summary(transaction).discountNominal.toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    )}
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: '87%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ width: '90%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          GST (inc)
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          :
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        {summary(transaction).gst.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: '87%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ width: '90%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          SubTotal
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          :
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        {summary(transaction).subTotal.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: '87%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ width: '90%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          Less Amount Paid
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          :
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        {summary(transaction).subTotal.toFixed(2)}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: 5,
                    }}
                  >
                    <View
                      style={{
                        width: '87%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ width: '90%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          Amount Due
                        </Text>
                      </View>
                      <View style={{ width: '3%' }}>
                        <Text
                          style={{
                            fontFamily: 'Helvetica-Bold',
                            fontSize: 10,
                            textAlign: 'right',
                          }}
                        >
                          :
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text
                        style={{
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        $
                      </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text
                        style={{
                          width: '80%',
                          fontFamily: 'Helvetica-Bold',
                          fontSize: 10,
                          textAlign: 'right',
                        }}
                      >
                        0
                      </Text>
                    </View>
                  </View>
                </View>

                {transaction.shipment.method === EShipmentMethod.DELIVERY && (
                  <View
                    wrap={false}
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      paddingVertical: '10px',
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                      borderLeftWidth: 1,
                      maxHeight: 150,
                    }}
                  >
                    <View style={{ width: '3%' }}></View>
                    <View style={{ width: '15%', marginVertical: 'auto' }}>
                      <Text
                        style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}
                      >
                        Delivery Note
                      </Text>
                    </View>
                    <View style={{ width: '5%', marginVertical: 'auto' }}>
                      <Text
                        style={{ fontFamily: 'Helvetica-Bold', fontSize: 10 }}
                      >
                        :
                      </Text>
                    </View>
                    <View style={{ width: '67%', marginVertical: 'auto' }}>
                      <Text style={{ fontFamily: 'Helvetica', fontSize: 10 }}>
                        {transaction.shipment.meta?.deliveryAddressNote
                          ? transaction.shipment.meta?.deliveryAddressNote
                          : ''}
                      </Text>
                    </View>
                  </View>
                )}
                <View
                  wrap={false}
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    height: 30,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                  }}
                >
                  <View
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                      borderBottomWidth: 1,
                      borderRightWidth: 1,
                    }}
                  >
                    <View
                      style={{
                        marginHorizontal: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ marginVertical: 'auto' }}>
                        <Image
                          src='/images/instagram.png'
                          style={{ width: '15px', height: '15px' }}
                        ></Image>
                      </View>
                      <Text
                        style={{
                          fontFamily: 'Helvetica',
                          fontSize: 10,
                          marginVertical: 'auto',
                          marginLeft: 5,
                        }}
                      >
                        @spoonfulkitchenmelbourne
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginHorizontal: 'auto',
                      borderBottomWidth: 1,
                    }}
                  >
                    <View
                      style={{
                        marginHorizontal: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <View style={{ marginVertical: 'auto' }}>
                        <Image
                          src='/images/browser.png'
                          style={{ width: '15px', height: '15px' }}
                        ></Image>
                      </View>
                      <Text
                        style={{
                          fontFamily: 'Helvetica',
                          fontSize: 10,
                          marginVertical: 'auto',
                          marginLeft: 5,
                        }}
                      >
                        https://spoonfulkitchen.com.au
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default MyDocument;
