import {
  AppstoreOutlined,
  TagOutlined,
  TeamOutlined,
  MessageOutlined,
  SettingOutlined,
  SafetyOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  ScheduleFilled,
  EnvironmentOutlined,
  DashboardOutlined,
  ShoppingOutlined,
  CalendarOutlined,
  InfoOutlined,
  InfoCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react"
import { useLocation } from "react-router-dom";
import { EPermissions } from "../helpers/permissions";
import useAuth from "../hooks/useAuth";
import useConfigApp from "../hooks/useConfigApp";
import AppMenuItem from "./AppMenuItem";
import { ReactComponent as FeesLogo } from "../assets/icons/fees_icon.svg";

const AppMenuSidebar: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { activeSubMenu, toggleActiveSideMenu } = useConfigApp();

  let permissions: EPermissions[] = [];
  if (user.role === "admin") {
    permissions = Object.values(EPermissions);
  } else if (user.role === "admin_chef") {
    permissions = [
      EPermissions.DASHBOARD_VIEW,
      EPermissions.ORDER_SUMMARY_VIEW,
    ];
  } else {
    permissions = [EPermissions.DASHBOARD_VIEW];
  }

  let menus: any[] = [
    {
      groupTitle: "Dashboard",
      menus: [
        {
          permission: permissions.includes(EPermissions.DASHBOARD_VIEW),
          label: "Dashboard",
          icon: <DashboardOutlined />,
          counter: 0,
          path: "/dashboard",
        },
      ],
    },
    {
      groupTitle: "Sales",
      menus: [
        {
          permission: permissions.includes(EPermissions.ORDER_VIEW),
          label: "Order",
          icon: <AppstoreOutlined />,
          counter: 0,
          path: "/order",
        },
        {
          permission: permissions.includes(EPermissions.ORDER_SUMMARY_VIEW),
          label: "Order Summary",
          icon: <AppstoreOutlined />,
          counter: 0,
          path: "/order-summary",
        },
        {
          permission: permissions.includes(EPermissions.PACKAGING_VIEW),
          label: "Packaging",
          icon: <InboxOutlined />,
          counter: 0,
          path: "/packaging",
        },
      ],
    },
    {
      groupTitle: "Master Data",
      menus: [
        {
          permission: permissions.includes(EPermissions.MENU_VIEW),
          label: "Menu",
          icon: <AppstoreOutlined />,
          counter: 0,
          path: "/menu",
        },
        {
          permission: permissions.includes(EPermissions.CATEGORY_VIEW),
          label: "Category",
          icon: <TagOutlined />,
          counter: 0,
          path: "/category",
        },
        {
          permission: permissions.includes(EPermissions.ALLERGEN_VIEW),
          label: "Dietaries",
          icon: <TagOutlined />,
          counter: 0,
          path: "/dietaries",
        },
        {
          permission: permissions.includes(EPermissions.LOCATION_VIEW),
          label: "Pickup Location",
          icon: <EnvironmentOutlined />,
          counter: 0,
          path: "/pickup-location",
        },
        {
          permission: permissions.includes(EPermissions.DELIVERY_COVERAGE),
          label: "Delivery Coverage",
          icon: <InfoCircleOutlined />,
          counter: 0,
          path: "/delivery-coverage",
        },
        {
          permission: permissions.includes(EPermissions.SCHEDULE_VIEW),
          label: "Food Schedule",
          icon: <CalendarOutlined />,
          counter: 0,
          path: "/schedule",
        },
        {
          permission: permissions.includes(EPermissions.DISCOUNT_VIEW),
          label: "Discount",
          icon: <ShoppingOutlined />,
          counter: 0,
          path: "/discount",
        },
      ],
    },
    {
      groupTitle: "User management",
      menus: [
        {
          permission: permissions.includes(EPermissions.ADMIN_VIEW),
          label: "User",
          icon: <TeamOutlined />,
          counter: 0,
          path: "/user",
        },
        {
          permission: permissions.includes(EPermissions.CUSTOMER_VIEW),
          label: "Customer",
          icon: <TeamOutlined />,
          counter: 0,
          path: "/customer",
        },
      ],
    },
    {
      groupTitle: "Feedback",
      menus: [
        {
          permission: permissions.includes(EPermissions.CUSTOMER_MESSAGE_VIEW),
          label: "Customer Message",
          icon: <MessageOutlined />,
          counter: 0,
          path: "/customer-message",
        },
      ],
    },
    {
      groupTitle: "Configuration",
      menus: [
        {
          permission: permissions.includes(EPermissions.BANNER_VIEW),
          subMenu: true,
          icon: <SettingOutlined />,
          subMenuTitleGroup: "Web Configuration",
          subMenuItems: [
            {
              label: "Header Banner",
              path: "/banner",
            },
            {
              label: "How It Work",
              path: "/how-it-work",
            },
            {
              label: "About Us",
              path: "/about-us",
            },
            {
              label: "App Config",
              path: "/app-config",
            },
          ],
        },
        {
          permission: permissions.includes(EPermissions.PRIVACY_POLICY_VIEW),
          label: "Privacy Policy",
          icon: <SafetyOutlined />,
          counter: 0,
          path: "/privacy-policy",
        },
        {
          permission: permissions.includes(
            EPermissions.TERM_AND_CONDITION_VIEW
          ),
          label: "Terms & Conditions",
          icon: <FileProtectOutlined />,
          counter: 0,
          path: "/terms-and-conditions",
        },
        {
          permission: permissions.includes(EPermissions.FAQ_VIEW),
          label: "FAQs",
          icon: <QuestionCircleOutlined />,
          counter: 0,
          path: "/faq",
        },
        // {
        //   permission: true,
        //   label: "Fees",
        //   icon: <FeesLogo />,
        //   counter: 0,
        //   path: "/fees",
        // },
      ],
    },
  ];

  menus = menus.filter(
    (item) => item.menus.filter((el: any) => el.permission).length > 0
  );

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    return res;
  };

  return (
    <Menu
      id="menu-sidebar"
      mode="inline"
      onOpenChange={(e) => toggleActiveSideMenu(e)}
      openKeys={activeSubMenu}
      defaultSelectedKeys={[convertPathName()]}
      // onClick={toggleActiveSideMenu}
      style={{ height: "85%", overflowX: "hidden", overflowY: "auto" }}
    >
      {menus.map((data: any, idx: number) => (
        <AppMenuItem key={data.groupTitle} data={data} />
      ))}
    </Menu>
  );
};

export default AppMenuSidebar;
